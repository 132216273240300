@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$lmg-primary: (
  50 : #e0eaee,
  100 : #b3cad5,
  200 : #80a7b9,
  300 : #4d839d,
  400 : #266988,
  500 : #004e73,
  600 : #00476b,
  700 : #003d60,
  800 : #003556,
  900 : #002543,
  A100 : #78b5ff,
  A200 : #4599ff,
  A400 : #127dff,
  A700 : #0070f7,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$flow-app-primary: mat-palette($lmg-primary);
$flow-app-accent:  mat-palette($lmg-primary, A200, A100, A400);

// The warn palette is optional (defaults to red).
$flow-app-warn:    mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$flow-app-theme: mat-light-theme($flow-app-primary, $flow-app-accent, $flow-app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($flow-app-theme);
